.screen-home-container {
    margin: auto;
    padding: 0 0 1vw;
}

.screen-home-container > section:not(.main):not(.timeline):not(.footer) {
    padding: 0 2vw;
}

.screen-home-container section.main {
    padding: 2vw 0;
    position: relative;
}

.screen-home-container section.main h1 {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    padding: 50px 2vw;
    text-align: center;
    white-space: pre-line;
}

.screen-home-container section.main .image {
    background-image: url("./assets/images/background.png");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    opacity: 0.7;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

.screen-home-container section.description {
    align-items: center;
    align-self: center;
    margin: 50px 0;
    max-width: 1000px;
}

.screen-home-container section.description .icon.icon-levcheto {
    height: 80px;
    width: 80px;
}

.screen-home-container section.description h1 {
    font-size: 30px;
    font-weight: 700;
}

.screen-home-container section.description h1,
.screen-home-container section.description p {
    text-align: center;
}

.screen-home-container section.sponsors h2 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
}

.screen-home-container section.sponsors .row {
    justify-content: space-evenly;
    margin: 30px 0;
    gap: 10px 20px;

    flex-wrap: wrap;
    width: 100%;
}

.screen-home-container section.sponsors a {
    flex: 1 1;
    max-width: 200px;
    min-height: 150px;
    flex-basis: 33%;
}

.screen-home-container section.sponsors .image {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    flex: 1 1;
    max-width: 200px;
    min-height: 150px;
}

.screen-home-container section.timeline {
    align-items: flex-start;
    justify-content: center;
    margin: 60px 0;
    min-width: 100%;
    width: 100%;
}

.screen-home-container section.timeline .col {
    flex-basis: 25%;
    position: relative;
    text-align: center;
}

.screen-home-container section.timeline p {
    font-size: 20px;
    font-weight: 700;
    margin: 5px 0;
    padding: 0 5px;
}

@media only screen and (max-width: 767px) {
    .screen-home-container section.timeline p {
        font-size: 13px;
    }
}

.screen-home-container section.timeline .dot {
    background-color: #000b1d;
    border-radius: 50%;
    height: 15px;
    margin: auto;
    width: 15px;
}

.screen-home-container section.timeline .dot .line {
    background-color: #000b1d;
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: 45px;
}

@media only screen and (max-width: 767px) {
    .screen-home-container section.timeline .dot .line {
        top: 35px;
    }
}

.screen-home-container section.footer .place-date h2 {
    text-align: center;
}

.screen-home-container section.footer .place-date h2 span {
    font-weight: 700;
}

@media only screen and (max-width: 767px) {
    .screen-home-container section.footer .place-date h2 {
        font-size: 18px;
    }
}

.screen-home-container section.footer > .row {
    margin: 30px 0;
    width: 100%;
}

.screen-home-container section.footer > .row > * {
    flex: 1 1;
    text-align: center;
}

.screen-home-container section.footer > .row .image {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 70px;
}

.screen-home-container section.footer > .row .row-social {
    justify-content: space-evenly;
}

.screen-home-container section.footer > .row .row-social .icon {
    height: 50px;
    width: 50px;
}

@media only screen and (max-width: 767px) {
    .screen-home-container section.footer > .row .row-social .icon {
        height: 30px;
        width: 30px;
    }
}

.screen-game-finished-container,
.screen-game-upcoming-container {
    display: flex;
    display: -webkit-flex;
}

.screen-game-container {
    grid-column-gap: 5%;
    -webkit-column-gap: 5%;
    column-gap: 5%;
    display: grid;
    grid-template-areas: "graph instruments" "news portfolio";
    grid-template-columns: 60% 35%;
    grid-template-rows: auto;
    padding: 1vw 2vw 50px;
}

.screen-game-container .game-graph-container {
    grid-area: graph;
}

.screen-game-container .game-news-container {
    grid-area: news;
}

.screen-game-container .game-instruments-container {
    grid-area: instruments;
}

.screen-game-container .game-portfolio-container {
    grid-area: portfolio;
}

@media only screen and (max-width: 767px) {
    .screen-game-container {
        -webkit-column-gap: 0;
        column-gap: 0;
        grid-template-areas: "graph" "instruments" "portfolio" "news";
        grid-template-columns: 100%;
    }
}

.screen-game-finished-container,
.screen-game-upcoming-container {
    align-items: center;
    height: calc(100vh - 60px);
    justify-content: center;
}

.screen-game-finished-container {
    flex-direction: column;
}

.screen-game-finished-container .game-portfolio-container {
    margin-top: 30px;
    min-width: 400px;
}

.screen-game-finished-container .game-portfolio-container .row-position {
    pointer-events: none;
}

.screen-admin-no-permissions {
    display: flex;
    display: -webkit-flex;
}

.screen-admin-container {
    align-items: center;
    height: calc(100vh - 60px);
    justify-content: center;
}

.screen-admin-container .row-buttons .shared-button-container {
    margin: 0 10px;
}

.screen-admin-container .users-container {
    margin-top: 30px;
    max-height: 600px;
    overflow: auto;
}

.screen-admin-container .users-container .row-user {
    margin: 10px 0;
}

.screen-admin-no-permissions {
    align-items: center;
    height: calc(100vh - 60px);
    justify-content: center;
}

.header-container {
    box-shadow: 0 5px 15px 0 rgba(0, 52, 112, 0.149);
    height: 60px;
    justify-content: space-between;
    padding: 10px 2vw;
}

.header-container img {
    height: 40px;
}

.header-container .row-buttons p.timer {
    color: gray;
    margin-left: 10px;
}

@media only screen and (max-width: 767px) {
    .header-container .row-buttons p.timer {
        margin-right: 30px;
    }
}

.header-container .row-buttons p.period {
    color: gray;
}

.header-container .row-buttons .divider {
    background-color: gray;
    height: 30px;
    margin-left: 10px;
    width: 1px;
}

.header-container .row-buttons .shared-button-container {
    margin-left: 10px;
}

.header-container .row-buttons .user-initials-container {
    background-color: #8a35ff;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    height: 40px;
    margin-left: 10px;
    padding: 6px;
    text-align: center;
    width: 40px;
}

@media only screen and (max-width: 767px) {
    .header-container {
        flex-wrap: wrap;
        height: auto;
    }

    .header-container a {
        margin: auto;
    }

    .header-container a img {
        flex-basis: 100%;
        margin-bottom: 10px;
    }

    .header-container .row-buttons {
        flex-basis: 100%;
        flex-wrap: wrap;
    }

    .header-container .row-buttons p.period {
        flex-basis: 50%;
        flex: 1 1;
        font-size: 14px;
        text-align: center;
        white-space: nowrap;
    }

    .header-container .row-buttons .divider {
        display: none;
        flex-basis: 100%;
        height: 0;
    }

    .header-container .row-buttons .user-initials-container {
        margin: 10px 0 0 auto;
    }

    .header-container .row-buttons .shared-button-container {
        flex-basis: 45%;
    }

    .header-container .row-buttons .shared-button-container:not(.next-period) {
        margin: 10px 0 0 auto;
    }

    .header-container .row-buttons .shared-button-container.next-period {
        font-size: 13px;
        margin-left: auto;
    }
}

[role="tooltip"].popup-content.popup-header-options-container-content {
    padding: 0 15px;
    width: auto;
}

[role="tooltip"].popup-content.popup-header-options-container-content .row-action {
    cursor: pointer;
}

[role="tooltip"].popup-content.popup-header-options-container-content .row-action .icon {
    margin-right: 15px;
}

[role="tooltip"].popup-content.popup-header-options-container-content .row-action p {
    font-weight: 700;
}

[role="tooltip"].popup-content.popup-header-options-container-content .row-action:active {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
}

.modal-login-container {
    padding: 5px 10px;
}

.modal-login-container .modal-login-header {
    justify-content: space-between;
}

.modal-login-container .modal-login-header .icon.icon-close {
    height: 20px;
    width: 20px;
}

.modal-login-container .modal-login-form {
    padding: 30px 0;
}

.modal-login-container > p {
    align-self: center;
    color: #8a35ff;
    cursor: pointer;
}

.popup-content.modal-loading-container-content {
    align-items: center;
    border: none;
    border-radius: 15px;
    box-shadow: 0 2px 3px 0 rgba(50, 50, 50, 0.3);
    display: flex;
    flex-direction: column;
    height: 470px;
    justify-content: center;
    padding: 0;
    text-align: center;
    width: 770px;
}

.popup-content.modal-loading-container-content .modal-loading-text {
    font-size: 25px;
    font-weight: 700;
    padding-bottom: 45px;
}

.button-underlined-container {
    position: relative;
}

.button-underlined-container.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.button-underlined-container .row {
    align-items: center;
    display: flex;
}

.button-underlined-container .underlined-tab {
    cursor: pointer;
    font-size: 14px;
    padding: 10px 32px 10px 0;
}

.button-underlined-container .active-underlined-selector-container {
    background-color: #ddd;
    height: 5px;
    width: 100%;
}

.button-underlined-container .active-underlined-selector-container.disable-track {
    background-color: transparent;
}

.button-underlined-container .active-underlined-selector {
    background-color: #09f;
    bottom: 0;
    height: 0;
    left: 0;
    position: absolute;
    transition: all 0.3s ease-in-out;
    width: 150px;
}

.button-underlined-container .active-underlined-selector.selector-add-height {
    height: 5px;
}

.shared-button-container {
    background-color: #ffb612;
    border: none;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    overflow: hidden;
    padding: 10px 15px;
    text-align: center;
}

.shared-button-container:active {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
}

.modal-register-container {
    padding: 5px 10px;
}

.modal-register-container .modal-register-header {
    justify-content: space-between;
}

.modal-register-container .modal-register-header .icon.icon-close {
    height: 20px;
    width: 20px;
}

.modal-register-container .modal-register-form {
    padding: 30px 0;
}

.modal-register-container .modal-register-form > .row > .col {
    flex: 1 1;
}

.modal-register-container p.existing-profile {
    align-self: center;
}

.modal-register-container p.existing-profile span {
    color: #8a35ff;
    cursor: pointer;
}

.modal-forgotten-password-container {
    padding: 5px 10px;
}

.modal-forgotten-password-container .modal-forgotten-password-header {
    justify-content: space-between;
}

.modal-forgotten-password-container .modal-forgotten-password-header h2 {
    margin-right: 40px;
}

.modal-forgotten-password-container .modal-forgotten-password-header .icon.icon-close {
    height: 20px;
    width: 20px;
}

.modal-forgotten-password-container .modal-forgotten-password-form {
    padding: 30px 0;
}

.modal-buy-position-container {
    padding: 10px 15px;
    position: relative;
}

.modal-buy-position-container .icon.icon-close {
    position: absolute;
    right: 20px;
    top: 20px;
}

.modal-buy-position-container .modal-buy-position-header h2 {
    font-weight: 700;
    margin-bottom: 0;
}

.modal-buy-position-container .modal-buy-position-header p {
    color: gray;
    margin-top: 10px;
}

.modal-buy-position-container .modal-buy-position-header p span {
    font-weight: 700;
}

.modal-buy-position-container .input-container input {
    border: none;
    border-bottom: 1px solid #ddd;
    font-size: 20px;
}

.modal-buy-position-container .input-container .shared-button-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 15px;
    padding: 10px;
}

.modal-buy-position-container .input-container .shared-button-container .icon {
    height: 20px;
    width: 20px;
}

.modal-buy-position-container .input-container p {
    color: gray;
    margin: 0;
}

.modal-buy-position-container .details-container {
    border-bottom: 1px solid #ddd;
    padding: 30px 0;
}

.modal-buy-position-container .details-container .row {
    justify-content: space-between;
}

.modal-buy-position-container .details-container p {
    margin: 3px 0;
}

.modal-buy-position-container .row-total {
    justify-content: space-between;
}

.modal-buy-position-container .row-total h3,
.modal-buy-position-container .row-total span {
    font-size: 20px;
    font-weight: 500;
}

.modal-buy-position-container .modal-buy-position-footer {
    padding: 15px 0;
}

.modal-buy-position-container .modal-buy-position-footer .shared-button-container {
    flex: 1 1;
}

.modal-sell-position-container {
    padding: 10px 15px;
    position: relative;
}

.modal-sell-position-container .icon.icon-close {
    position: absolute;
    right: 20px;
    top: 20px;
}

.modal-sell-position-container .modal-sell-position-header h2 {
    font-weight: 700;
    margin-bottom: 0;
}

.modal-sell-position-container .modal-sell-position-header p {
    color: gray;
    margin-top: 10px;
}

.modal-sell-position-container .modal-sell-position-header p span {
    font-weight: 700;
}

.modal-sell-position-container .details-container {
    border-bottom: 1px solid #ddd;
    padding: 30px 0;
}

.modal-sell-position-container .details-container .row {
    justify-content: space-between;
}

.modal-sell-position-container .details-container .row.row-profit.positive p,
.modal-sell-position-container .details-container .row.row-profit.positive span {
    color: #00ce14;
}

.modal-sell-position-container .details-container .row.row-profit.negative p,
.modal-sell-position-container .details-container .row.row-profit.negative span {
    color: #fa4460;
}

.modal-sell-position-container .details-container p {
    margin: 3px 0;
}

.modal-sell-position-container .row-total {
    justify-content: space-between;
}

.modal-sell-position-container .row-total h3,
.modal-sell-position-container .row-total span {
    font-size: 20px;
    font-weight: 500;
}

.modal-sell-position-container .modal-sell-position-footer {
    padding: 15px 0;
}

.modal-sell-position-container .modal-sell-position-footer .shared-button-container {
    flex: 1 1;
}

.popup-content {
    background: #fff;
    border: 1px solid #d7d7d7;
    margin: auto;
    padding: 5px;
    width: 50%;
}

[role="tooltip"].popup-content {
    border-radius: 5px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
    width: 200px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}

[data-popup="tooltip"].popup-overlay {
    background: transparent;
}

.popup-arrow {
    stroke-width: 2px;
    stroke: #d7d7d7;
    stroke-dasharray: 30px;
    stroke-dashoffset: -54px;
    bottom: 0;
    color: #fff;
    -webkit-filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
    left: 0;
    right: 0;
    top: 0;
}

.popup-content.modal-container-content {
    border: none;
    border-radius: 16px;
    max-width: 90%;
    min-width: 40%;
    width: auto;
}

.game-graph-container {
    margin-bottom: 30px;
}

.game-graph-container h1,
.game-graph-container h2,
.game-graph-container > p {
    margin: 8px 0;
}

.game-graph-container h1 {
    font-size: 24px;
    font-weight: 500;
}

.game-graph-container h1 p {
    display: inline-block;
    margin-left: 30px;
}

.game-graph-container h2 {
    color: gray;
    font-size: 20px;
}

.game-graph-container > p {
    font-weight: 300;
}

.game-graph-container > div.echarts-for-react {
    padding: 30px 0;
}

.game-graph-container .row-buttons > button {
    align-items: center;
    display: flex;
    flex: 1 1;
    justify-content: center;
}

.game-graph-container .row-buttons > button .icon {
    display: inline-block;
    margin-right: 10px;
}

.game-graph-container .row-buttons > button .icon.icon-short {
    height: 25px;
    width: 25px;
}

.game-news-container h2 {
    margin-bottom: 0;
}

.game-news-container .game-news-product-container {
    margin-top: 30px;
}

.game-news-container .game-news-product-container > p,
.game-news-container .game-news-products-container > p,
.game-news-container .game-news-sectors-container > p {
    color: gray;
    font-size: 20px;
    margin: 15px 0;
    text-align: center;
}

.game-news-container .game-news-product-container > .row,
.game-news-container .game-news-products-container > .row,
.game-news-container .game-news-sectors-container > .row {
    align-items: flex-start;
    padding: 10px 0;
}

.game-news-container .game-news-product-container > .row:not(:last-child),
.game-news-container .game-news-products-container > .row:not(:last-child),
.game-news-container .game-news-sectors-container > .row:not(:last-child) {
    border-bottom: 1px solid #ddd;
}

.game-news-container .game-news-product-container > .row span:first-child,
.game-news-container .game-news-products-container > .row span:first-child,
.game-news-container .game-news-sectors-container > .row span:first-child {
    color: gray;
    flex: 1 1;
}

.game-news-container .game-news-product-container > .row span:nth-child(2),
.game-news-container .game-news-products-container > .row span:nth-child(2),
.game-news-container .game-news-sectors-container > .row span:nth-child(2) {
    flex: 2 1;
    padding-left: 10px;
}

.game-instruments-container {
    border-radius: 16px;
    box-shadow: 0 5px 15px 0 rgba(0, 52, 112, 0.149);
    margin-bottom: 30px;
    max-height: 750px;
    overflow: hidden;
    padding: 15px 10px;
}

.game-instruments-container h2 {
    border-bottom: 1px solid gray;
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    padding-bottom: 15px;
}

.game-instruments-container .game-instruments-content {
    max-height: 670px;
    overflow: scroll;
}

.game-instruments-container .game-instruments-content .row-group-header {
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    justify-content: space-between;
}

.game-instruments-container .game-instruments-content .group-container {
    height: 0;
    overflow: hidden;
    transition: all 0.4s ease-in;
}

.game-instruments-container .game-instruments-content .group-container.expanded {
    height: auto;
}

.game-instruments-container .game-instruments-content h3 {
    color: gray;
    margin: 0;
    padding: 10px 0;
}

.game-instruments-container .game-instruments-content .row-product {
    cursor: pointer;
    justify-content: space-between;
    margin: 5px 0;
}

.game-instruments-container .game-instruments-content .row-product:active {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
}

.game-instruments-container .game-instruments-content .row-product.selected p,
.game-instruments-container .game-instruments-content .row-product.selected span {
    font-weight: 700;
}

.game-instruments-container .game-instruments-content .row-product p {
    font-size: 18px;
    padding-right: 15px;
}

.game-instruments-container .game-instruments-content .row-product .col-price {
    align-items: flex-end;
}

.game-portfolio-container {
    border-radius: 16px;
    box-shadow: 0 5px 15px 0 rgba(0, 52, 112, 0.149);
    margin-bottom: 30px;
    max-height: 550px;
    overflow: hidden;
    padding: 15px 10px;
}

.game-portfolio-container h2 {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    padding-bottom: 15px;
}

.game-portfolio-container .row-header {
    border-bottom: 1px solid gray;
    justify-content: space-between;
}

.game-portfolio-container .row-header p {
    margin: 0;
}

.game-portfolio-container .game-portfolio-header {
    padding: 10px 0;
}

.game-portfolio-container .game-portfolio-header .row {
    justify-content: space-between;
}

.game-portfolio-container .game-portfolio-header .row p {
    margin: 2px 0 2px 20px;
}

.game-portfolio-container .game-portfolio-header .row p.total {
    margin-left: 0;
}

.game-portfolio-container .game-portfolio-header .row p,
.game-portfolio-container .game-portfolio-header .row span {
    color: gray;
}

.game-portfolio-container .game-portfolio-header .row p.total,
.game-portfolio-container .game-portfolio-header .row span.total {
    color: #000b1d;
    font-weight: 500;
}

.game-portfolio-container .game-portfolio-header .row p.bold,
.game-portfolio-container .game-portfolio-header .row span.bold {
    font-weight: 700;
}

.game-portfolio-container .game-portfolio-sell-all {
    padding: 10px 0;
}

.game-portfolio-container .game-portfolio-sell-all .row-title {
    height: 40px;
    justify-content: space-between;
}

.game-portfolio-container .game-portfolio-sell-all .row-title p {
    margin: 0;
}

.game-portfolio-container .game-portfolio-sell-all span {
    color: gray;
}

.game-portfolio-container .game-portfolio-content {
    overflow: scroll;
}

.game-portfolio-container .game-portfolio-content p.no-positions {
    font-weight: 700;
    text-align: center;
}

.game-portfolio-container .game-portfolio-content .row-position {
    cursor: pointer;
    justify-content: space-between;
    padding: 15px 0;
}

.game-portfolio-container .game-portfolio-content .row-position:not(:last-child) {
    border-bottom: 1px solid #ddd;
}

.game-portfolio-container .game-portfolio-content .row-position p {
    margin: 0;
}

.game-portfolio-container .game-portfolio-content .row-position .col-value {
    align-items: flex-end;
}

.col,
.row {
    display: flex;
    display: -webkit-flex;
}

@font-face {
    font-family: CorsaGrotesk;
    font-style: normal;
    font-weight: 100;
    src: url("./assets/fonts/CorsaGrotesk-Thin.ttf") format("truetype");
}

@font-face {
    font-family: CorsaGrotesk;
    font-style: normal;
    font-weight: 300;
    src: url("./assets/fonts/CorsaGrotesk-Light.ttf") format("truetype");
}

@font-face {
    font-family: CorsaGrotesk;
    font-style: normal;
    font-weight: 400;
    src: url("./assets/fonts/CorsaGrotesk-Regular.ttf") format("truetype");
}

@font-face {
    font-family: CorsaGrotesk;
    font-style: normal;
    font-weight: 500;
    src: url("./assets/fonts/CorsaGrotesk-Medium.ttf") format("truetype");
}

@font-face {
    font-family: CorsaGrotesk;
    font-style: normal;
    font-weight: 700;
    src: url("./assets/fonts/CorsaGrotesk-Bold.ttf") format("truetype");
}

*,
:after,
:before {
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
}

#root,
body,
html {
    margin: 0;
    padding: 0;
}

#root,
a,
body,
button,
div,
h1,
h2,
h3,
h4,
h5,
h6,
html,
input,
li,
p,
span {
    color: #000b1d;
    font-family: CorsaGrotesk, sans-serif;
    font-weight: 400;
}

.row {
    align-items: center;
    flex-direction: row;
}

.col {
    flex-direction: column;
}

body .icon {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    height: 30px;
    width: 30px;
}

body .icon.icon-close {
    background-image: url("./assets/icons/close.svg");
}

body .icon.icon-logout {
    background-image: url("./assets/icons/logout.svg");
}

body .icon.icon-long {
    background-image: url("./assets/icons/long.svg");
}

body .icon.icon-short {
    background-image: url("./assets/icons/short.svg");
}

body .icon.icon-plus {
    background-image: url("./assets/icons/plus.svg");
}

body .icon.icon-minus {
    background-image: url("./assets/icons/minus.svg");
}

body .icon.icon-arrow-down {
    background-image: url("./assets/icons/arrow-down.svg");
}

body .icon.icon-facebook {
    background-image: url("./assets/icons/facebook.svg");
}

body .icon.icon-instagram {
    background-image: url("./assets/icons/instagram.svg");
}

body .icon.icon-linkedin {
    background-image: url("./assets/icons/linkedin.svg");
}

body .icon.icon-levcheto {
    background-image: url("./assets/icons/levcheto.png");
}

body .icon:active {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
}

body .icon.no-active {
    cursor: default;
}

body .icon.no-active:active {
    -webkit-transform: scale(1);
    transform: scale(1);
}

body .icon.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

body .icon.disabled:active {
    -webkit-transform: scale(1);
    transform: scale(1);
}

body .icon.no-pointer {
    cursor: default;
}

input {
    border: 1px solid rgba(0, 52, 112, 0.3);
    border-radius: 5px;
    color: #000b1d;
    flex: 1 1;
    font-size: 13px;
    height: 38px;
    margin: 10px 0;
    padding: 9px 11px;
    width: 100%;
}

input.invalid {
    border-color: #fff;
    box-shadow: 0 0 3px #fa4460;
}

p.change,
span.change {
    position: relative;
}

p.change:after,
span.change:after {
    border-radius: 10px;
    content: "";
    height: 0;
    left: -25px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
    width: 0;
}

p.change.positive,
span.change.positive {
    color: #00ce14;
}

p.change.positive:after,
span.change.positive:after {
    border-bottom: 10px solid #00ce14;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

p.change.negative,
span.change.negative {
    color: #fa4460;
}

p.change.negative:after,
span.change.negative:after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fa4460;
}

p.change.zero:after,
span.change.zero:after {
    border-color: #000b1d;
    border-style: solid;
    border-width: 1px 5px;
    left: -15px;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
}

/*# sourceMappingURL=main.39122ebf.css.map*/
